import { CDK_DRAG_HANDLE } from "@angular/cdk/drag-drop";
import { MenuButton } from "./../../core/services/interface";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_CODE,
  MENU_BUTTON_FORM,
  MENU_BUTTON_STYLE,
  SPLASH_STYLE,
  TabNames,
  splash_config_map,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-offcanvas-button-settings",
  templateUrl: "./offcanvas-button-settings.component.html",
  styleUrls: ["./offcanvas-button-settings.component.scss"],
})
export class OffcanvasButtonSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  offcanvas_data: any = [];
  offCanvasSubscription: any;

  // default values
  colors: any;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "tab";
  button_id: string;

  page_id: string;
  title: string;
  hideoffcanvas: boolean;
  current_tab: string;
  active_tabset_index: number = 0; // to start with specific index
  tab: Tab;
  updateBuilderComponentSubscription: any;
  data: any;
  button: MenuButton;
  no_button: any;

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (data){
          this.data = data;
          this.OffCanvasData(data, "offcan");

          if (data && data.button && data.no_button  ){
            this.no_button = data.no_button
          }else {
            this.no_button =1;
          }
        }

      }
    );
    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data && data.tab && data.offcanvasRefersh) {
          // offcanvasRefresh prevents flickering and prevent the component (offcanvas) to update itself and then components flikers.
          // only update from outside component can send updated data to the offcanvas.
          this.OffCanvasData(this.data, "updateBuilder");
        }
      });
  }

  OffCanvasData(data, value) {
    if (data) {
      if (data.off_canvas_key == "button") {
        this.button_id = data.button.button_id;
        this.title = data.button.button_name;
        this.button = data.button;
        this.hideoffcanvas = true;
        const module = data.data.module;

        if (data.data.tab) {
          this.current_tab = data.data.tab;
        }

        if (data.colors) {
          this.colors = data.colors;
        }

        let button_code: string;
        // only for splash, we will take the code from the tab not from the button to open the proper settings.
        if (data.data.splashTab.cat === "splash") {
          button_code = "splash_" + data.data.splashTab.param.splash.style;
        } else {
          button_code = data.button.button_code;
        }
        //***************************************************** */
        switch (button_code) {
          case MENU_BUTTON_CODE.BUTTON_04:
            this.active_tabset_index = 0; // to select initial opening setting tab.
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: true,
                          trailing_icon: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_color: "surfaceContainerLow",
                          init_color_ios: "surfaceContainer",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },

             ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_01:
            this.active_tabset_index = 0; // to select initial opening setting tab.
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "onPrimary",
                          init_color_ios: "onPrimary",
                          scheme_color: this.colors,
                          icon: true,
                          trailing_icon: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onPrimary",
                          init_color_ios: "onPrimary",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              }
              ,

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_03:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "onSecondaryContainer",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: true,
                          trailing_icon: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSecondaryContainer",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_color: "secondaryContainer",

                          init_color_ios: "primaryContainer",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },


                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_02:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: true,
                          trailing_icon: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Outline",
                          setcolor: true,
                          color: "outline",
                          init_color: "outline",
                          init_color_ios: "outline",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },

                ],
              },

                      ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_05:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: true,
                          trailing_icon: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.INPUT_01:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: true,
                          trailing_icon: true,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_placeholder",
                          name: "Placeholder",
                          text: true,
                          setcolor: true,
                          color: "placeholder",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                      },
                    } ,
                    {
                          button_main: true,
                          config: {
                            id: "button_helper",
                            name: "Helper",
                            text: true,
                            setcolor: true,
                            color: "helper",
                            init_color: "onSurfaceVariant",
                            init_color_ios: "onSurfaceVariant",
                            scheme_color: this.colors,
                          },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Leading Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Trailling Icon",
                          setcolor: true,
                          color: "trailing_icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Outline",
                          setcolor: true,
                          color: "outline",
                          init_color: "outline",
                          init_color_ios: "outline",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },


                    ],
                  },
                ],
              },
              {
                tab: "Advanced settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 1),
                data: [
                  {
                    cat: "Advanced",
                    show: false,
                    list: [
                      {
                        button_validations: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.INPUT_02:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: true,
                          trailing_icon: true,
                        },
                      },    {
                        button_main: true,
                        config: {
                          id: "button_placeholder",
                          name: "Placeholder",
                          text: true,
                          setcolor: true,
                          color: "placeholder",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                      },
                    } ,
                      {
                        button_main: true,
                        config: {
                          id: "button_helper",
                          name: "Helper",
                          text: true,
                          setcolor: true,
                          color: "helper",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },

                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Leading Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Trailling Icon",
                          setcolor: true,
                          color: "trailing_icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Outline",
                          setcolor: true,
                          color: "outline",
                          init_color: "outline",
                          init_color_ios: "outline",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                tab: "Advanced settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 1),
                data: [
                  {
                    cat: "Advanced",
                    show: false,
                    list: [
                      {
                        button_validations: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.INPUT_03:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: true,
                          trailing_icon: true,
                        },
                      },    {
                        button_main: true,
                        config: {
                          id: "button_placeholder",
                          name: "Placeholder",
                          text: true,
                          setcolor: true,
                          color: "placeholder",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                      },
                    } ,
                      {
                        button_main: true,
                        config: {
                          id: "button_helper",
                          name: "Helper",
                          text: true,
                          setcolor: true,
                          color: "helper",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Leading Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Trailling Icon",
                          setcolor: true,
                          color: "trailing_icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Outline",
                          setcolor: true,
                          color: "outline",
                          init_color: "outline",
                          init_color_ios: "outline",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                tab: "Advanced settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 1),
                data: [
                  {
                    cat: "Advanced",
                    show: false,
                    list: [
                      {
                        button_validations: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                        },
                      },
                    ],
                  },
                ],
              },
             ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.OUTPUT_01:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "headline",
                          align_key: "headline",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          align_key: "subhead",
                          font_key: "subhead",
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.OUTPUT_02:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: false,
                          font_key: "headline",
                          align_key: "headline",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: false,
                          align_key: "subhead",
                          font_key: "subhead",
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.OUTPUT_03:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "headline",
                          align_key: "headline",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          align_key: "subhead",
                          font_key: "subhead",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_body",
                          name: "Body",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "body",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          align_key: "body",
                          font_key: "body",
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.TOGGLE_1000:
          case MENU_BUTTON_CODE.SINGLECHOICE_1000:
          case MENU_BUTTON_CODE.MULTICHOICE_1000:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_option: true,
                        config: {
                          id: "button_option",
                          name: "Options",
                          icon: true,
                          trailing_icon: false,
                          image: false,
                          divider: true,
                          max: 6, //unlimited
                          maxSelected: 1,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: true,
                          text: true,
                          validation: { required: false },
                          setcolor: false,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "headline",
                          align_key: "headline",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          validation: { required: false },
                          setcolor: false,
                          color: "subhead",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "subhead",
                          align_key: "subhead",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_helper",
                          name: "Helper",
                          text: true,
                          setcolor: true,
                          color: "helper",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_option_color",
                          name: "Option",
                          setcolor: true,
                          color: "option",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: false,
                        config: {
                          id: "button_bg_color",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_color: "surfaceContainerHighest",
                          init_color_ios: "surfaceContainerHighest",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_outline_color",
                          name: "Divider",
                          setcolor: true,
                          color: "outline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "style",
                          style: false,
                          mirror: true,
                          divider: true,
                          card: true,
                          card_types: ['0','1','2', '3', '4'],
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;

          case MENU_BUTTON_CODE.MULTICHOICE_SEGMENTED_1000:
              this.offcanvas_data = [
                {
                  tab: "Settings",
                  id: this.button.button_code,
                  icon: this.button.button_icon,
                  active: this.activeTabSetting(this.button.button_id, 0),
                  data: [
                    {
                      cat: "Settings",
                      show: false,
                      list: [
                        {
                          button_option: true,
                          config: {
                            id: "button_option",
                            name: "Options",
                            icon: true,
                            trailing_icon: false,
                            image: false,
                            divider: false,
                            max: 5, //unlimited
                            maxSelected: 1,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_color_icon",
                            name: "Outline",
                            density: true,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_color_icon",
                            name: "Outline",
                            setcolor: true,
                            color: "outline",
                            init_color: "outline",
                            init_color_ios: "outline",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_color_icon",
                            name: "Icon",
                            setcolor: true,
                            color: "icon",
                            init_color: "onSurface",
                            init_color_ios: "n3onSurface",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_bg_color",
                            name: "Background",
                            setcolor: true,
                            color: "bg",
                            init_color: "secondaryContainer",
                            init_color_ios: "n2SurfaceContainerLowest",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_bg_color",
                            name: "label",
                            setcolor: true,
                            color: "label",
                            init_color: "onSurface",
                            init_color_ios: "n3onSurface",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_style: false,
                          config: {
                            id: "style",
                            name: "style",
                            style: false,
                            mirror: true,
                            divider: true,
                            card: true,
                            card_types: ['0', '1','2'],
                          },
                        },
                      ],
                    },
                  ],
                },

              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              break;
          case MENU_BUTTON_CODE.SINGLECHOICE_SEGMENTED_1000:
              this.offcanvas_data = [
                {
                  tab: "Settings",
                  id: this.button.button_code,
                  icon: this.button.button_icon,
                  active: this.activeTabSetting(this.button.button_id, 0),
                  data: [
                    {
                      cat: "Settings",
                      show: false,
                      list: [
                        {
                          button_option: true,
                          config: {
                            id: "button_option",
                            name: "Options",
                            icon: true,
                            trailing_icon: false,
                            image: false,
                            divider: false,
                            max: 5, //unlimited
                            maxSelected: 1,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_color_icon",
                            name: "Outline",
                            density: true,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_color_icon",
                            name: "Icon",
                            setcolor: true,
                            color: "icon",
                            init_color: "onSurface",
                            init_color_ios: "n3onSurface",
                            scheme_color: this.colors,
                          },
                        },

                        {
                          button_main: true,
                          config: {
                            id: "button_bg_color",
                            name: "Background",
                            setcolor: true,
                            color: "bg",
                            init_color: "secondaryContainer",
                            init_color_ios: "n2SurfaceContainerLowest",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_bg_color",
                            name: "label",
                            setcolor: true,
                            color: "label",
                            init_color: "onSurface",
                            init_color_ios: "n3onSurface",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_style: false,
                          config: {
                            id: "style",
                            name: "style",
                            style: false,
                            mirror: true,
                            divider: true,
                            card: true,
                            card_types: ['0', '1','2'],
                          },
                        },
                      ],
                    },
                  ],
                },

              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              break;
              case MENU_BUTTON_CODE.MUTLI_CHIPSET_0100:
              case MENU_BUTTON_CODE.MUTLI_CHIPSET_0102:
              case MENU_BUTTON_CODE.MUTLI_CHIPSET_0200:
              case MENU_BUTTON_CODE.MUTLI_CHIPSET_0202:
                this.offcanvas_data = [
                  {
                    tab: "Settings",
                    id: this.button.button_code,
                    icon: this.button.button_icon,
                    active: this.activeTabSetting(this.button.button_id, 0),
                    data: [
                      {
                        cat: "Settings",
                        show: false,
                        list: [
                          {
                            button_option: true,
                            config: {
                              id: "button_option",
                              name: "Options",
                              icon: true,
                              trailing_icon: false,
                              image: false,
                              divider: false,
                              max: 0, //unlimited
                              maxSelected: 0,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_bg_color",
                              name: "Background",
                              setcolor: true,
                              color: "bg",
                              init_color: "secondaryContainer",
                              init_color_ios: "n2SurfaceContainerLowest",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_color_icon",
                              name: "Icon",
                              setcolor: true,
                              color: "icon",
                              init_color: "onSurface",
                              init_color_ios: "n3onSurface",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_bg_color",
                              name: "label",
                              setcolor: true,
                              color: "label",
                              init_color: "onSurface",
                              init_color_ios: "n3onSurface",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_style: true,
                            config: {
                              id: "style",
                              name: "style",
                              style: false,
                              mirror: false,
                              divider: false,
                              card: true,
                              card_types: ['0','2'],
                            },
                          },
                        ],
                      },
                    ],
                  },

                ];
                if (data.show) {
                  this.showOffcanvas();
                } else {
                  this.hideOffcanvas();
                }
                break;
              case MENU_BUTTON_CODE.SINGLE_CHIPSET_0100:
              case MENU_BUTTON_CODE.SINGLE_CHIPSET_0102:
              case MENU_BUTTON_CODE.SINGLE_CHIPSET_0200:
              case MENU_BUTTON_CODE.SINGLE_CHIPSET_0202:
                this.offcanvas_data = [
                  {
                    tab: "Settings",
                    id: this.button.button_code,
                    icon: this.button.button_icon,
                    active: this.activeTabSetting(this.button.button_id, 0),
                    data: [
                      {
                        cat: "Settings",
                        show: false,
                        list: [
                          {
                            button_option: true,
                            config: {
                              id: "button_option",
                              name: "Options",
                              icon: true,
                              trailing_icon: false,
                              image: false,
                              divider: false,
                              max: 0, //unlimited
                              maxSelected: 1,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_bg_color",
                              name: "Background",
                              setcolor: true,
                              color: "bg",
                              init_color: "secondaryContainer",
                              init_color_ios: "n2SurfaceContainerLowest",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_color_icon",
                              name: "Icon",
                              setcolor: true,
                              color: "icon",
                              init_color: "onSurface",
                              init_color_ios: "n3onSurface",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_bg_color",
                              name: "label",
                              setcolor: true,
                              color: "label",
                              init_color: "onSurface",
                              init_color_ios: "n3onSurface",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_style: true,
                            config: {
                              id: "style",
                              name: "style",
                              style: false,
                              mirror: false,
                              divider: false,
                              card: true,
                              card_types: ['0','2'],
                            },
                          },
                        ],
                      },
                    ],
                  },

                ];
                if (data.show) {
                  this.showOffcanvas();
                } else {
                  this.hideOffcanvas();
                }
                break;
                case MENU_BUTTON_CODE.SEARCHBAR_01:
                  this.offcanvas_data = [
                    {
                      tab: "Settings",
                      id: this.button.button_code,
                      icon: this.button.button_icon,
                      active: this.activeTabSetting(this.button.button_id, 0),
                      data: [
                        {
                          cat: "Settings",
                          show: false,
                          list: [
                            {
                              button_main: true,
                              config: {
                                id: "button_label",
                                name: "Label",
                                title: true,
                                text: false,
                                validation: { required: true },
                                setcolor: false,
                                color: "label",
                                init_color: "primary",
                                init_color_ios: "primary",
                                scheme_color: this.colors,
                                icon: true,
                                trailing_icon: true,
                              },
                            },
                            {
                              button_main: true,
                              config: {
                                id: "button_placeholder",
                                name: "Placeholder",
                                text: true,
                                setcolor: true,
                                color: "placeholder",
                                init_color: "onSurfaceVariant",
                                init_color_ios: "onSurfaceVariant",
                                scheme_color: this.colors,
                            },
                          } ,
                          {
                            button_main: true,
                            config: {
                              id: "button_color_icon",
                              name: "Background Color",
                              setcolor: true,
                              color: "bg",
                              init_color: "surfaceContainerHigh",
                              init_color_ios: "surfaceContainerHigh",
                              scheme_color: this.colors,
                            },
                          },
                            {
                              button_main: true,
                              config: {
                                id: "button_color_icon",
                                name: "Leading Icon",
                                setcolor: true,
                                color: "icon",
                                init_color: "onSurface",
                                init_color_ios: "onSurface",
                                scheme_color: this.colors,
                              },
                            },
                            {
                              button_main: true,
                              config: {
                                id: "button_color_icon",
                                name: "Trailling Icon",
                                setcolor: true,
                                color: "trailing_icon",
                                init_color: "onSurface",
                                init_color_ios: "onSurface",
                                scheme_color: this.colors,
                              },
                            },
                            {
                              button_style: false,
                              config: {
                                id: "style",
                                name: "style",
                                style: false,
                                mirror: false,
                                divider: false,
                                card: true,
                                card_types: ['0','1','2', '3', '4'],
                              },
                            },

                            {
                              search_query: true,
                              config: {
                                id: "search_query",
                                name: "Label",
                                title: true,
                                validation: { required: false },
                              },
                            },
                          ],
                        },
                      ],
                    },

                  ];
                  if (data.show) {
                    this.showOffcanvas();
                  } else {
                    this.hideOffcanvas();
                  }
                  break;
                  case MENU_BUTTON_CODE.SLIDER_0100:
                  case MENU_BUTTON_CODE.SLIDER_0200:
                  case MENU_BUTTON_CODE.SLIDER_0201:
                    this.offcanvas_data = [
                      {
                        tab: "Settings",
                        id: this.button.button_code,
                        icon: this.button.button_icon,
                        active: this.activeTabSetting(this.button.button_id, 0),
                        data: [
                          {
                            cat: "Settings",
                            show: false,
                            list: [
                              {
                                button_main: true,
                                config: {
                                  id: "button_active_slider_color",
                                  name: "Active Color",
                                  setcolor: true,
                                  color: "active",
                                  init_color: "primary",
                                  init_color_ios: "primary",
                                  scheme_color: this.colors,
                                },
                              },
                              {
                                button_main: true,
                                config: {
                                  id: "button_inactive_slider_color",
                                  name: "Active Color",
                                  setcolor: true,
                                  color: "inactive",
                                  init_color: "secondaryContainer",
                                  init_color_ios: "secondaryContainer",
                                  scheme_color: this.colors,
                                },
                              },
                              {
                                button_slider: true,
                                config: {
                                  step: false
                                },
                              },
                            ],
                          },
                        ],
                      },

                    ];
                    if (data.show) {
                      this.showOffcanvas();
                    } else {
                      this.hideOffcanvas();
                    }
                    break;
                    case MENU_BUTTON_CODE.SLIDER_0101:
                      this.offcanvas_data = [
                        {
                          tab: "Settings",
                          id: this.button.button_code,
                          icon: this.button.button_icon,
                          active: this.activeTabSetting(this.button.button_id, 0),
                          data: [
                            {
                              cat: "Settings",
                              show: false,
                              list: [
                                {
                                  button_main: true,
                                  config: {
                                    id: "button_active_slider_color",
                                    name: "Active Color",
                                    setcolor: true,
                                    color: "active",
                                    init_color: "primary",
                                    init_color_ios: "primary",
                                    scheme_color: this.colors,
                                  },
                                },
                                {
                                  button_main: true,
                                  config: {
                                    id: "button_inactive_slider_color",
                                    name: "Active Color",
                                    setcolor: true,
                                    color: "inactive",
                                    init_color: "secondaryContainer",
                                    init_color_ios: "secondaryContainer",
                                    scheme_color: this.colors,
                                  },
                                },
                                {
                                  button_slider: true,
                                  config: {
                                    step: true
                                  },
                                },
                              ],
                            },
                          ],
                        },

                      ];
                      if (data.show) {
                        this.showOffcanvas();
                      } else {
                        this.hideOffcanvas();
                      }
                      break;

              case MENU_BUTTON_CODE.ASSIST_CHIPSET_0100:
              case MENU_BUTTON_CODE.ASSIST_CHIPSET_0102:
                this.offcanvas_data = [
                  {
                    tab: "Settings",
                    id: this.button.button_code,
                    icon: this.button.button_icon,
                    active: this.activeTabSetting(this.button.button_id, 0),
                    data: [
                      {
                        cat: "Settings",
                        show: false,
                        list: [
                          {
                            button_option: true,
                            config: {
                              id: "button_option",
                              name: "Options",
                              icon: true,
                              trailing_icon: false,
                              image: false,
                              divider: false,
                              max: 0, //unlimited
                              maxSelected: 1,
                              hideValue: true
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_bg_color",
                              name: "Background",
                              setcolor: true,
                              color: "bg",
                              init_color: "secondaryContainer",
                              init_color_ios: "n2SurfaceContainerLowest",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_color_icon",
                              name: "Icon",
                              setcolor: true,
                              color: "icon",
                              init_color: "onSurface",
                              init_color_ios: "n3onSurface",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_bg_color",
                              name: "label",
                              setcolor: true,
                              color: "label",
                              init_color: "onSurface",
                              init_color_ios: "n3onSurface",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_style: true,
                            config: {
                              id: "style",
                              name: "style",
                              style: false,
                              mirror: false,
                              divider: false,
                              card: true,
                              card_types: ['0','2'],
                            },
                          },
                        ],
                      },
                    ],
                  },

                ];
                if (data.show) {
                  this.showOffcanvas();
                } else {
                  this.hideOffcanvas();
                }
                break;
              case MENU_BUTTON_CODE.DROPDOWN_CHIP_02:
                this.offcanvas_data = [
                  {
                    tab: "Settings",
                    id: this.button.button_code,
                    icon: this.button.button_icon,
                    active: this.activeTabSetting(this.button.button_id, 0),
                    data: [
                      {
                        cat: "Settings",
                        show: false,
                        list: [
                          {
                            button_main: true,
                            config: {
                              id: "button_label",
                              name: "Label",
                              title: true,
                              text: true,
                              validation: { required: true },
                              setcolor: true,
                              color: "label",
                              init_color: "primary",
                              init_color_ios: "primary",
                              scheme_color: this.colors,
                              icon: false,
                              trailing_icon: false,
                            },
                          },
                          {
                            button_option: true,
                            config: {
                              id: "button_option",
                              name: "Options",
                              icon: true,
                              trailing_icon: false,
                              image: false,
                              divider: true,
                              max: 6, //unlimited
                              maxSelected: 1,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_helper",
                              name: "Helper",
                              text: true,
                              setcolor: true,
                              color: "helper",
                              init_color: "onSurfaceVariant",
                              init_color_ios: "onSurfaceVariant",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_color_icon",
                              name: "Icon",
                              setcolor: true,
                              color: "icon",
                              init_color: "onSurfaceVariant",
                              init_color_ios: "onSurfaceVariant",
                              scheme_color: this.colors,
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_color_icon",
                              name: "Outline",
                              setcolor: true,
                              color: "outline",
                              init_color: "outline",
                              init_color_ios: "outline",
                              scheme_color: this.colors,
                            },
                          },

                          {
                            button_style: true,
                            config: {
                              id: "button_style",
                              name: "Button Style",
                              style: true,
                              card_types: ['0','2'],
                            },
                          },
                        ],
                      },
                    ],
                  },
                 ];
                if (data.show) {
                  this.showOffcanvas();
                } else {
                  this.hideOffcanvas();
                }
                break;
          case MENU_BUTTON_CODE.TOGGLE_2000:
          case MENU_BUTTON_CODE.SINGLECHOICE_2000:
          case MENU_BUTTON_CODE.MULTICHOICE_2000:
          this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_option: true,
                        config: {
                          id: "button_option",
                          name: "Options",
                          icon: false,
                          trailing_icon: false,
                          image: true,
                          divider: true,
                          max: 0, //unlimited
                          maxSelected: 1,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: true,
                          text: true,
                          validation: { required: false },
                          setcolor: false,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "headline",
                          align_key: "headline",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          validation: { required: false },
                          setcolor: false,
                          color: "subhead",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "subhead",
                          align_key: "subhead",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_helper",
                          name: "Helper",
                          text: true,
                          setcolor: true,
                          color: "helper",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_option_color",
                          name: "Option",
                          setcolor: true,
                          color: "option",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: false,
                        config: {
                          id: "button_bg_color",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_color: "surfaceContainerHighest",
                          init_color_ios: "surfaceContainerHighest",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_outline_color",
                          name: "Divider",
                          setcolor: true,
                          color: "outline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "style",
                          style: false,
                          mirror: true,
                          divider: true,
                          card: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.TOGGLE_3000:
          case MENU_BUTTON_CODE.SINGLECHOICE_3000:
          case MENU_BUTTON_CODE.MULTICHOICE_3000:
          this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_option: true,
                        config: {
                          id: "button_option",
                          name: "Options",
                          icon: false,
                          trailing_icon: false,
                          image: false,
                          divider: true,
                          max: 0, //unlimited
                          maxSelected: 1,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: true,
                          text: true,
                          validation: { required: false },
                          setcolor: false,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "headline",
                          align_key: "headline",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          validation: { required: false },
                          setcolor: false,
                          color: "subhead",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "subhead",
                          align_key: "subhead",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_helper",
                          name: "Helper",
                          text: true,
                          setcolor: true,
                          color: "helper",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: false,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_option_color",
                          name: "Option",
                          setcolor: true,
                          color: "option",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: false,
                        config: {
                          id: "button_bg_color",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_color: "surfaceContainerHighest",
                          init_color_ios: "surfaceContainerHighest",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_outline_color",
                          name: "Divider",
                          setcolor: true,
                          color: "outline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "style",
                          style: false,
                          mirror: true,
                          divider: true,
                          card: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.DROPDOWN_01:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                        },
                      },
                      {
                        button_option: true,
                        config: {
                          id: "button_option",
                          name: "Options",
                          icon: true,
                          trailing_icon: false,
                          image: false,
                          divider: true,
                          max: 6, //unlimited
                          maxSelected: 1,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_helper",
                          name: "Helper",
                          text: true,
                          setcolor: true,
                          color: "helper",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Outline",
                          setcolor: true,
                          color: "outline",
                          init_color: "outline",
                          init_color_ios: "outline",
                          scheme_color: this.colors,
                        },
                      },

                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },
             ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.DROPDOWN_02:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                        },
                      },
                      {
                        button_option: true,
                        config: {
                          id: "button_option",
                          name: "Options",
                          icon: true,
                          trailing_icon: false,
                          image: false,
                          divider: true,
                          max: 6, //unlimited
                          maxSelected: 1,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_helper",
                          name: "Helper",
                          text: true,
                          setcolor: true,
                          color: "helper",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },

                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Trailling Icon",
                          setcolor: true,
                          color: "trailing_icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_outline",
                          name: "Outline",
                          setcolor: true,
                          color: "outline",
                          init_color: "outline",
                          init_color_ios: "outline",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },
                      ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.DROPDOWN_03:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_label",
                          name: "Label",
                          title: true,
                          text: true,
                          validation: { required: true },
                          setcolor: true,
                          color: "label",
                          init_color: "primary",
                          init_color_ios: "primary",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                        },
                      },
                      {
                        button_option: true,
                        config: {
                          id: "button_option",
                          name: "Options",
                          icon: true,
                          trailing_icon: false,
                          image: false,
                          divider: true,
                          max: 6, //unlimited
                          maxSelected: 1,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_helper",
                          name: "Helper",
                          text: true,
                          setcolor: true,
                          color: "helper",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: false,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_color: "surfaceContainerHighest",
                          init_color_ios: "surfaceContainerHighest",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Outline",
                          setcolor: true,
                          color: "outline",
                          init_color: "outline",
                          init_color_ios: "outline",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "button_style",
                          name: "Button Style",
                          style: true,
                          card_types: ['0', '1','2'],
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
            case MENU_BUTTON_CODE.CAROUSEL_01:
            case MENU_BUTTON_CODE.CAROUSEL_02:
            case MENU_BUTTON_CODE.CAROUSEL_03:
            case MENU_BUTTON_CODE.CAROUSEL_04:
              this.offcanvas_data = [
                  {
                    tab: "Settings",
                    id: this.button.button_code,
                    icon: this.button.button_icon,
                    active: this.activeTabSetting(this.button.button_id, 0),
                    data: [
                      {
                        cat: "Settings",
                        show: false,
                        list: [
                          {
                            button_option: true,
                            config: {
                              id: "button_option",
                              name: "Options",
                              icon: false,
                              trailing_icon: false,
                              image: true,
                              divider: true,
                              max: 0, //unlimited
                              maxSelected: 1,
                              hideValue: true,
                              sublabel: true
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_headline",
                              name: "Headline",
                              title: true,
                              text: true,
                              validation: { required: false },
                              setcolor: false,
                              color: "headline",
                              init_color: "onSurfaceVariant",
                              init_color_ios: "onSurfaceVariant",
                              scheme_color: this.colors,
                              icon: false,
                              trailing_icon: false,
                              font: true,
                              align: true,
                              font_key: "headline",
                              align_key: "headline",
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_option_label",
                              name: "option label",
                              font: true,
                              align: true,
                              font_key: "option_label",
                              align_key: "option_label",
                            },
                          },
                          {
                            button_main: true,
                            config: {
                              id: "button_option_sublabel",
                              name: "option sublabel",
                              font: true,
                              align: true,
                              font_key: "option_sublabel",
                              align_key: "option_sublabel",
                            },
                          },
                        ],
                      },
                    ],
                  },

                ];
                if (data.show) {
                  this.showOffcanvas();
                } else {
                  this.hideOffcanvas();
                }
                break;
          case MENU_BUTTON_CODE.SEPARATOR_01:
              this.active_tabset_index = 0; // to select initial opening setting tab.
              this.offcanvas_data = [
                {
                  tab: "Settings",
                  id: this.button.button_code,
                  icon: this.button.button_icon,
                  active: this.activeTabSetting(this.button.button_id, 0),
                  data: [
                    {
                      cat: "Settings",
                      show: false,
                      list: [
                        {
                          button_main: true,
                          config: {
                            id: "button_headline",
                            name: "Headline",
                            title: true,
                            text: true,
                            validation: { required: false },
                            setcolor: false,
                            color: "headline",
                            init_color: "onSurfaceVariant",
                            init_color_ios: "onSurfaceVariant",
                            scheme_color: this.colors,
                            icon: false,
                            trailing_icon: false,
                            font: true,
                            align: true,
                            font_key: "headline",
                            align_key: "headline",
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_subhead",
                            name: "Subhead",
                            title: false,
                            text: true,
                            validation: { required: false },
                            setcolor: false,
                            color: "subhead",
                            init_color: "onSurfaceVariant",
                            init_color_ios: "onSurfaceVariant",
                            scheme_color: this.colors,
                            icon: false,
                            trailing_icon: false,
                            font: true,
                            align: true,
                            font_key: "subhead",
                            align_key: "subhead",
                          },
                        },

                        {
                          button_main: true,
                          config: {
                            id: "button_divider",
                            name: "Divider",
                            title: false,
                            text: false,
                            validation: { required: false },
                            setcolor: false,
                            icon: false,
                            trailing_icon: false,
                            font: false,
                            align: false,
                            divider: true,

                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_color_outline",
                            name: "Divider",
                            setcolor: true,
                            color: "outline",
                            init_color: "outline",
                            init_color_ios: "outline",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_headline",
                            name: "Headline",
                            setcolor: true,
                            color: "headline",
                            init_color: "onSurfaceVariant",
                            init_color_ios: "onSurfaceVariant",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_subhead",
                            name: "subhead",
                            setcolor: true,
                            color: "subhead",
                            init_color: "onSurfaceVariant",
                            init_color_ios: "onSurfaceVariant",
                            scheme_color: this.colors,
                          },
                        },

                        {
                          button_main: false,
                          config: {
                            id: "button_color_bg",
                            name: "Background",
                            setcolor: true,
                            color: "bg",
                            init_color: "surfaceContainerLow",
                            init_color_ios: "surfaceContainer",
                            scheme_color: this.colors,
                          },
                        },
                        {
                          button_style: true,
                          config: {
                            id: "button_style",
                            name: "Button Style",
                            style: true,
                            card_types: ['0', '1','2'],
                          },
                        },
                      ],
                    },
                  ],
                },
               ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              break;
          case MENU_BUTTON_FORM.PAGESLIDER:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_label: true },
                      { button_subLable: true },
                      { pageMultiImage: true },
                      { pageSlideAnimation: true },
                      { pageInterval: true },
                      { pageSlideStyle: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGESLIDERLABEL:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_label: true },
                      { button_subLable: true },
                      { pageSwaperiImage: true },
                      { pageSlideAnimation: true },
                      { pageInterval: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEGALLERY:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageMultiImage: true },
                      { pageGalleryStyle: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGESINGLEIMAGE:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_label: true },
                      { button_subLable: true },
                      { pageSingleImageUploader: true },
                      { pageSingleImageStyle: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEHEADER:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageHeader: true },
                      { pageHeaderImage: true },
                      { pageBgColors: true },
                      { pageStylePosition: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGESOCIALBAR:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageSocialBar: true },
                      { pageSocialBarStyle: true },
                      { pageStylePosition: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGELOCATION:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageLocationCenter: true },
                      { pageLocationZoom: true },
                      { pageLocationMarker: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEVIDEO:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_label: true },
                      { button_subLable: true },
                      { pageVideo: true },
                      { pageVideoStyle: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEQR:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [{ pageQr: true }, { pageStylePosition: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEDIVIDER:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageBorderColor: true },
                      { pageBorderSize: true },
                      { pageBorderMt: true },
                      { pageBorderMb: true },
                      { pageBorderMsMe: true },
                      { pageBorderStyle: true },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.SPLASH_01:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [{
                      buttonsplashimage: true,
                      config: {
                        id: "button_color_icon",
                        name: "Option",
                        image: true,
                        imageType: "image/jpeg,image/pjpeg,image/png",
                        // aspectRatio: this.button.info.ratio[this.no_button],
                        resizeToWidth:260,
                        resizeToHeight:260,
                        cropperView: true,
                        keyword:"Card",
                        title: "Card Image",
                        removeButton: true,
                        isDark: false,
                        // bg: true
                      },
                    }],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true,
                        config: {
                        id: "button_color_bg",
                        name: "Background",
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['btn_text'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['btn_bg'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['btn_text'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['btn_bg'],
                        scheme_color: this.colors,
                      } },
                      { btabsplashtnc: true,
                        config: {
                        bg: true,
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['tnc_text'],
                        init_link: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['tnc_link'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['tnc_text'],
                        init_link_ios: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['tnc_link'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['tnc_bg'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_01].tab_part['tnc_bg'],
                        scheme_color: this.colors,
                       }
                      },
                      { btabsplashterm: true, config: { bg: true } },
                    ],
                  },
                ],
              },
            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_02:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      {
                        buttonsplashimage: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          // aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:260,
                          resizeToHeight:260,
                          cropperView: true,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: false,
                          // bg: true
                        },
                      },
                      { buttonsplashlabelhtml: true },
                      {
                        buttonsplashbgcolor: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_start: splash_config_map[SPLASH_STYLE.STYLE_02].button_part.bgstart,
                          init_end: splash_config_map[SPLASH_STYLE.STYLE_02].button_part.bgend,
                          init_start_ios: splash_config_map[SPLASH_STYLE.STYLE_02].button_part.bgstart,
                          init_end_ios: splash_config_map[SPLASH_STYLE.STYLE_02].button_part.bgend,
                          scheme_color: this.colors,
                        }
                      },,
                    ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true, config: {
                        id: "button_color_bg",
                        name: "Background",
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['btn_text'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['btn_bg'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['btn_text'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['btn_bg'],
                        scheme_color: this.colors,
                      } },
                      { btabsplashtnc: true,config: {
                        bg: false,
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['tnc_text'],
                        init_link: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['tnc_link'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['tnc_text'],
                        init_link_ios: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['tnc_link'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['tnc_bg'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_02].tab_part['tnc_bg'],
                        scheme_color: this.colors,
                       }  },
                      { btabsplashterm: true, config: { bg: true } },
                    ],
                  },
                ],
              },
            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_03:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [{ buttonsplashbgimage: true, config: {
                      id: "button_color_icon",
                      name: "Option",
                      image: true,
                      imageType: "image/jpeg,image/pjpeg,image/png",
                      // aspectRatio: this.button.info.ratio[this.no_button],
                      resizeToWidth:260,
                      resizeToHeight:260,
                      cropperView: true,
                      keyword:"Card",
                      title: "Card Image",
                      removeButton: true,
                      isDark: false,
                      // bg: true
                    }, }],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashtitlehtml: true },
                      { btabsplashbutton: true, config: {
                        id: "button_color_bg",
                        name: "Background",
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['btn_text'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['btn_bg'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['btn_text'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['btn_bg'],
                        scheme_color: this.colors,
                      } },
                      { btabsplashtnc: true, config: {
                        bg: false,
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['tnc_text'],
                        init_link: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['tnc_link'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['tnc_text'],
                        init_link_ios: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['tnc_link'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['tnc_bg'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_03].tab_part['tnc_bg'],
                        scheme_color: this.colors,
                       }  },
                      { btabsplashterm: true, config: { bg: true } },
                    ],
                  },
                ],
              },
            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_04:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      {
                        buttonsplashimage: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          // aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:260,
                          resizeToHeight:260,
                          cropperView: true,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: false,
                          // bg: true
                        },
                      },
                      { buttonsplashlabelhtml: true },
                      {
                        buttonsplashbgcolor: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_start: splash_config_map[SPLASH_STYLE.STYLE_04].button_part.bgstart,
                          init_end: splash_config_map[SPLASH_STYLE.STYLE_04].button_part.bgend,
                          init_start_ios: splash_config_map[SPLASH_STYLE.STYLE_04].button_part.bgstart,
                          init_end_ios: splash_config_map[SPLASH_STYLE.STYLE_04].button_part.bgend,
                          scheme_color: this.colors,
                        }
                      },,
                    ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true, config: {
                        id: "button_color_bg",
                        name: "Background",
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['btn_text'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['btn_bg'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['btn_text'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['btn_bg'],
                        scheme_color: this.colors,
                      } },
                      { btabsplashtnc: true, config: {
                        bg: true,
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['tnc_text'],
                        init_link: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['tnc_link'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['tnc_text'],
                        init_link_ios: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['tnc_link'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['tnc_bg'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_04].tab_part['tnc_bg'],
                        scheme_color: this.colors,
                      }  },
                      { btabsplashterm: true, config: { bg: true } },
                    ],
                  },
                ],
              },
            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_05:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      {
                        buttonsplashimage: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          // aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:260,
                          resizeToHeight:260,
                          cropperView: true,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: false,
                          // bg: true
                        },
                      },
                      { buttonsplashlabelhtml: true },
                      {
                        buttonsplashbgcolor: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_start: splash_config_map[SPLASH_STYLE.STYLE_05].button_part.bgstart,
                          init_end: splash_config_map[SPLASH_STYLE.STYLE_05].button_part.bgend,
                          init_start_ios: splash_config_map[SPLASH_STYLE.STYLE_05].button_part.bgstart,
                          init_end_ios: splash_config_map[SPLASH_STYLE.STYLE_05].button_part.bgend,
                          scheme_color: this.colors,
                        }
                      },,
                    ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true, config: {
                        id: "button_color_bg",
                        name: "Background",
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['btn_text'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['btn_bg'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['btn_text'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['btn_bg'],
                        scheme_color: this.colors,
                      } },
                      { btabsplashtnc: true, config: {
                        bg: true,
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_text'],
                        init_link: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_link'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_text'],
                        init_link_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_link'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_bg'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_bg'],
                        scheme_color: this.colors,
                      }  },
                      { btabsplashterm: true, config: { bg: true } },
                    ],
                  },
                ],
              },
            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_05:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      {
                        buttonsplashimage: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          // aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:260,
                          resizeToHeight:260,
                          cropperView: true,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: false,
                          // bg: true
                        },
                      },
                      { buttonsplashlabelhtml: true },
                      {
                        buttonsplashbgcolor: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_start: splash_config_map[SPLASH_STYLE.STYLE_05].button_part.bgstart,
                          init_end: splash_config_map[SPLASH_STYLE.STYLE_05].button_part.bgend,
                          init_start_ios: splash_config_map[SPLASH_STYLE.STYLE_05].button_part.bgstart,
                          init_end_ios: splash_config_map[SPLASH_STYLE.STYLE_05].button_part.bgend,
                          scheme_color: this.colors,
                        }
                      },,
                    ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true, config: {
                        id: "button_color_bg",
                        name: "Background",
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['btn_text'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['btn_bg'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['btn_text'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['btn_bg'],
                        scheme_color: this.colors,
                      } },
                      { btabsplashtnc: true, config: {
                        bg: true,
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_text'],
                        init_link: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_link'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_text'],
                        init_link_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_link'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_bg'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_05].tab_part['tnc_bg'],
                        scheme_color: this.colors,
                       }  },
                      { btabsplashterm: true, config: { bg: true } },
                    ],
                  },
                ],
              },
            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_06:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      {
                        buttonsplashimage: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          // aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:260,
                          resizeToHeight:260,
                          cropperView: true,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: false,
                          // bg: true
                        },
                      },
                      { buttonsplashlabelhtml: true },
                      {
                        buttonsplashbgcolor: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_start: splash_config_map[SPLASH_STYLE.STYLE_06].button_part.bgstart,
                          init_end: splash_config_map[SPLASH_STYLE.STYLE_06].button_part.bgend,
                          init_start_ios: splash_config_map[SPLASH_STYLE.STYLE_06].button_part.bgstart,
                          init_end_ios: splash_config_map[SPLASH_STYLE.STYLE_06].button_part.bgend,
                          scheme_color: this.colors,
                        }
                      },,
                    ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true, config: {
                        id: "button_color_bg",
                        name: "Background",
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['btn_text'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['btn_bg'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['btn_text'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['btn_bg'],
                        scheme_color: this.colors,
                      } },
                      { btabsplashtnc: true, config: {
                        bg: false,
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['tnc_text'],
                        init_link: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['tnc_link'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['tnc_text'],
                        init_link_ios: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['tnc_link'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['tnc_bg'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_06].tab_part['tnc_bg'],
                        scheme_color: this.colors,
                       }  },
                      { btabsplashterm: true, config: { bg: true } },
                    ],
                  },
                ],
              },
            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_07:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      {
                        buttonsplashimage: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          // aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:260,
                          resizeToHeight:260,
                          cropperView: true,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: false,
                          // bg: true
                        },
                      },
                      { buttonsplashlabelhtml: true },
                      {
                        buttonsplashbgcolor: true,
                        config: {
                          id: "button_color_bg",
                          name: "Background",
                          setcolor: true,
                          color: "bg",
                          init_start: splash_config_map[SPLASH_STYLE.STYLE_07].button_part.bgstart,
                          init_end: splash_config_map[SPLASH_STYLE.STYLE_07].button_part.bgend,
                          init_start_ios: splash_config_map[SPLASH_STYLE.STYLE_07].button_part.bgstart,
                          init_end_ios: splash_config_map[SPLASH_STYLE.STYLE_07].button_part.bgend,
                          scheme_color: this.colors,
                        }
                      },
                    ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true, config: {
                        id: "button_color_bg",
                        name: "Background",
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['btn_text'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['btn_bg'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['btn_text'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['btn_bg'],
                        scheme_color: this.colors,
                      } },
                      { btabsplashtnc: true,
                        config: {
                        bg: false,
                        setcolor: true,
                        color: "bg",
                        init_text: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['tnc_text'],
                        init_link: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['tnc_link'],
                        init_text_ios: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['tnc_text'],
                        init_link_ios: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['tnc_link'],
                        init_bg: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['tnc_bg'],
                        init_bg_ios: splash_config_map[SPLASH_STYLE.STYLE_07].tab_part['tnc_bg'],
                        scheme_color: this.colors,
                        }
                      },
                      { btabsplashterm: true, config: { bg: true } },
                    ],
                  },
                ],
              },
            ];
            break;
          case MENU_BUTTON_CODE.CARD_03:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        buttonimages: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:this.parseDimensions(this.button.info.ratio[this.no_button]).width,
                          resizeToHeight:this.parseDimensions(this.button.info.ratio[this.no_button]).height,
                          cropperView: false,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: true,
                          // bg: true
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,

                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "headline",
                          align_key: "headline",
                          setGradient: true,
                          init_start: 'surfaceContainer',
                          init_end: 'surfaceContainer',
                          init_start_ios: 'surfaceContainer',
                          init_end_ios: 'surfaceContainer',
                          divider: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          align_key: "subhead",
                          font_key: "subhead",
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "Card Type",
                          style: false,
                          mirror: false,
                          divider: false,
                          card: true,
                          card_types: ['0','1','2', '3', '4'],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                tab: "Theme",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 1),
                data: [
                  {
                    cat: "Theme",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config: {
                          id: "button_color_icon",
                          name: "Icon",
                          setcolor: true,
                          color: "icon",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "style",
                          style: true,
                          mirror: false,
                          divider: false,
                          card: true,
                          card_types: ['0','2', '3', '4'],
                        },
                      },
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.CARD_04:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        button_main: true,
                        config:
                        {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "headline",
                          align_key: "headline",
                          setGradient: true,
                          init_start: 'primaryContainer',
                          init_end: 'primary',
                          init_start_ios: 'primaryContainer',
                          init_end_ios: 'primary',
                          divider: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          align_key: "subhead",
                          font_key: "subhead",
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "Card Type",
                          style: false,
                          mirror: false,
                          divider: false,
                          card: true,
                          card_types: ['0','1','2', '3', '4'],
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.CARD_05:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        buttonimages: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:this.parseDimensions(this.button.info.ratio[this.no_button]).width,
                          resizeToHeight:this.parseDimensions(this.button.info.ratio[this.no_button]).height,
                          cropperView: false,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: true,
                          bg: true
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          font_key: "headline",
                          align_key: "headline",
                          setGradient: false,
                          init_start: 'surfaceContainer',
                          init_end: 'surfaceContainer',
                          init_start_ios: 'surfaceContainer',
                          init_end_ios: 'surfaceContainer',
                          divider: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: true,
                          align_key: "subhead",
                          font_key: "subhead",
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "Card Type",
                          style: false,
                          mirror: false,
                          divider: false,
                          card: true,
                          card_types: ['0','1','2', '3', '4'],
                        },
                      },
                    ],
                  },
                ],
              },
                         ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.CARD_06:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        buttonimages: true,
                        config: {
                        title:"Card Image",
                        image: true,
                        imageType: "image/jpeg,image/pjpeg,image/png",
                        aspectRatio: this.button.info.ratio[this.no_button],
                        resizeToWidth:this.parseDimensions(this.button.info.ratio[this.no_button]).width,
                        resizeToHeight:this.parseDimensions(this.button.info.ratio[this.no_button]).height,
                        cropperView: false,
                        keyword:"Card",
                        removeButton: true,
                        isDark: true,
                      },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: false,
                          font_key: "headline",
                          align_key: "headline",
                          setGradient: true,
                          init_start: 'primaryContainer',
                          init_end: 'primaryContainer',
                          init_start_ios: 'primaryContainer',
                          init_end_ios: 'primaryContainer',
                          divider: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: false,
                          align_key: "subhead",
                          font_key: "subhead",
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "Card Type",
                          style: false,
                          mirror: false,
                          divider: false,
                          card: true,
                          card_types: ['0','1','2', '3', '4'],
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.CARD_07:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        buttonimages: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:this.parseDimensions(this.button.info.ratio[this.no_button]).width,
                          resizeToHeight:this.parseDimensions(this.button.info.ratio[this.no_button]).height,
                          cropperView: false,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: true,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: false,
                          font_key: "headline",
                          align_key: "headline",
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: false,
                          align_key: "subhead",
                          font_key: "subhead",
                          setGradient: true,
                          init_start: 'primary',
                          init_end: 'primary',
                          init_start_ios: 'primary',
                          init_end_ios: 'primary',
                          divider: false,
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "Card Type",
                          style: false,
                          mirror: false,
                          divider: false,
                          card: true,
                          card_types: ['0','1','2', '3', '4'],
                        },
                      },
                    ],
                  },
                ],
              },

            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.CARD_08:
            this.offcanvas_data = [
              {
                tab: "Settings",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active: this.activeTabSetting(this.button.button_id, 0),
                data: [
                  {
                    cat: "Settings",
                    show: false,
                    list: [
                      {
                        buttonimages: true,
                        config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:this.parseDimensions(this.button.info.ratio[this.no_button]).width,
                          resizeToHeight:this.parseDimensions(this.button.info.ratio[this.no_button]).height,
                          cropperView: false,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: true,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_headline",
                          name: "Headline",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "headline",
                          init_color: "onSurfaceVariant",
                          init_color_ios: "onSurfaceVariant",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: false,
                          font_key: "headline",
                          align_key: "headline",
                          setGradient: true,
                          init_start: 'secondary',
                          init_end: 'secondary',
                          init_start_ios: 'secondary',
                          init_end_ios: 'secondary',
                          divider: false,
                        },
                      },
                      {
                        button_main: true,
                        config: {
                          id: "button_subhead",
                          name: "Subhead",
                          title: false,
                          text: true,
                          setcolor: true,
                          color: "subhead",
                          init_color: "onSurface",
                          init_color_ios: "onSurface",
                          scheme_color: this.colors,
                          icon: false,
                          trailing_icon: false,
                          font: true,
                          align: false,
                          align_key: "subhead",
                          font_key: "subhead",
                        },
                      },
                      {
                        button_style: true,
                        config: {
                          id: "style",
                          name: "Card Type",
                          style: false,
                          mirror: false,
                          divider: false,
                          card: true,
                          card_types: ['0','1','2', '3', '4'],
                        },
                      },
                    ],
                  },
                ],
              },


            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break
          case MENU_BUTTON_CODE.COLLECTION_01:
          case MENU_BUTTON_CODE.COLLECTION_02:
          case MENU_BUTTON_CODE.COLLECTION_05:
          case MENU_BUTTON_CODE.COLLECTION_07:
          case MENU_BUTTON_CODE.COLLECTION_08:
          case MENU_BUTTON_CODE.COLLECTION_11:
          case MENU_BUTTON_CODE.COLLECTION_12:
              this.offcanvas_data = [
                {
                  tab: "Settings",
                  id: this.button.button_code,
                  icon: this.button.button_icon,
                  active: this.activeTabSetting(this.button.button_id, 0),
                  data: [
                    {
                      cat: "Settings",
                      show: false,
                      list: [
                        {
                          buttonimages: true,
                          config: {
                          id: "button_color_icon",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          aspectRatio: this.button.info.ratio[this.no_button],
                          resizeToWidth:this.parseDimensions(this.button.info.ratio[this.no_button]).width,
                          resizeToHeight:this.parseDimensions(this.button.info.ratio[this.no_button]).height,
                          cropperView: false,
                          keyword:"Card",
                          title: "Card Image",
                          removeButton: true,
                          isDark: true,
                          },
                        },
                        {
                          onClickSelector: true,
                          config: {
                            id: "button_label",
                            name: module === 'market' ? "Category": "Collection",
                            color: "label",
                            init_color: "primary",
                            init_color_ios: "primary",
                            scheme_color: this.colors,
                            isCollection: true,
                            category: module === 'market' ? ["booking", "event"] : ["product", "digital"]
                          },
                        },
                        {
                          button_main: true,
                          config: {
                            id: "button_headline",
                            name: "Headline",
                            title: false,
                            text: true,
                            setcolor: true,
                            color: "headline",
                            init_color: "onSurfaceVariant",
                            init_color_ios: "onSurfaceVariant",
                            scheme_color: this.colors,
                            icon: false,
                            trailing_icon: false,
                            font: true,
                            align: true,
                            font_key: "headline",
                            align_key: "headline",
                          },
                        },

                        {
                          button_style: true,
                          config: {
                            id: "style",
                            name: "style",
                            style: true,
                            mirror: false,
                            divider: false,
                            card: true,
                            card_types: ['0', '1','2'],
                          },
                        },

                      ],
                    },
                  ],
                },

              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              break;
          case MENU_BUTTON_CODE.COLLECTION_13:
          case MENU_BUTTON_CODE.COLLECTION_14:
          case MENU_BUTTON_CODE.COLLECTION_15:
          case MENU_BUTTON_CODE.COLLECTION_16:
              this.offcanvas_data = [
                {
                  tab: "Settings",
                  id: this.button.button_code,
                  icon: this.button.button_icon,
                  active: this.activeTabSetting(this.button.button_id, 0),
                  data: [
                    {
                      cat: "Settings",
                      show: false,
                      list: [
                        {
                          buttonimages: true,
                          config: {
                            id: "button_color_icon",
                            name: "Option",
                            image: true,
                            imageType: "image/jpeg,image/pjpeg,image/png",
                            aspectRatio: this.button.info.ratio[this.no_button],
                            resizeToWidth:this.parseDimensions(this.button.info.ratio[this.no_button]).width,
                            resizeToHeight:this.parseDimensions(this.button.info.ratio[this.no_button]).height,
                            cropperView: false,
                            keyword:"Card",
                            title: "Card Image",
                            removeButton: true,
                            isDark: true,
                          },
                        },
                        {
                          onClickSelector: true,
                          config: {
                            id: "button_label",
                            name: module === 'market' ? "Category": "Collection",
                            color: "label",
                            init_color: "primary",
                            init_color_ios: "primary",
                            scheme_color: this.colors,
                            isCollection: true,
                            category: module === 'market' ? ["booking", "event"] : ["product", "digital"]
                          },
                        },

                        {
                          button_main: true,
                          config: {
                            id: "button_headline",
                            name: "Headline",
                            title: false,
                            text: true,
                            setcolor: false,
                            color: "headline",
                            init_color: "onSurfaceVariant",
                            init_color_ios: "onSurfaceVariant",
                            scheme_color: this.colors,
                            icon: false,
                            trailing_icon: false,
                            font: true,
                            align: false,
                            font_key: "headline",
                            align_key: "headline",
                          },
                        },

                        {
                          button_style: true,
                          config: {
                            id: "style",
                            name: "style",
                            style: true,
                            mirror: false,
                            divider: false,
                            card: true,
                            card_types: ['0', '1','2'],
                          },
                        },

                      ],
                    },
                  ],
                },

              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              break;
          case MENU_BUTTON_CODE.COLLECTION_09:
          case MENU_BUTTON_CODE.COLLECTION_10:
          case MENU_BUTTON_CODE.COLLECTION_04:
                this.offcanvas_data = [
                  {
                    tab: "Settings",
                    id: this.button.button_code,
                    icon: this.button.button_icon,
                    active: this.activeTabSetting(this.button.button_id, 0),
                    data: [
                      {
                        cat: "Settings",
                        show: false,
                        list: [
                          {
                            buttonimages: true,
                            config: {
                              id: "button_color_icon",
                              name: "Option",
                              image: true,
                              imageType: "image/jpeg,image/pjpeg,image/png",
                              aspectRatio: this.button.info.ratio[this.no_button],
                              resizeToWidth:this.parseDimensions(this.button.info.ratio[this.no_button]).width,
                              resizeToHeight:this.parseDimensions(this.button.info.ratio[this.no_button]).height,
                              cropperView: false,
                              keyword:"Collection",
                              title: "Collection Image",
                              removeButton: true,
                              isDark: true,
                            },
                          },

                          {
                            onClickSelector: true,
                            config: {
                              id: "button_label",
                              name: module === 'market' ? "Category": "Collection",
                              color: "label",
                              init_color: "primary",
                              init_color_ios: "primary",
                              scheme_color: this.colors,
                              isCollection: true,
                              category: module === 'market' ? ["booking", "event"] : ["product", "digital"]
                            },
                          },

                          {
                            button_style: true,
                            config: {
                              id: "style",
                              name: "style",
                              style: true,
                              mirror: false,
                              divider: false,
                              card: true,
                              card_types: ['0', '1','2'],
                            },
                          },
                        ],
                      },
                    ],
                  },

                ];
                if (data.show) {
                  this.showOffcanvas();
                } else {
                  this.hideOffcanvas();
                }
                break
              default:
            {
              this.hideoffcanvas = true;
            }
            break;
        }

        if (this.hideoffcanvas && data.show) {
          this.showOffcanvas();
          this.hideoffcanvas = false;
        } else {
          this.hideOffcanvas();
        }
        this.off_canvas_key = data.off_canvas_key;
      } else {
        this.hideOffcanvas();
      }
    }
  }

  activeTabSetting(id, tabIndex) {
    if (id === this.button_id) {
      if (tabIndex === this.active_tabset_index) {
        return true;
      } else return false;
    } else return false;
  }

  tabSetChange(event) {
    // only active to trigger on certian page components
    switch (this.button.button_code) {
      case "":
      case "":
        // write the fullction here function required when selection is make

        break;
    }
  }

  parseDimensions(dimensions: string): { width: number; height: number } {
    const [width, height] = dimensions.split('x').map(Number);
    return { width, height };
  }

  hideOffcanvas() {
    document
      .querySelector("#offcanvasScrollingButtonConf")
      .classList.remove("show");
  }

  showOffcanvas() {
    document
      .querySelector("#offcanvasScrollingButtonConf")
      .classList.add("show");
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if(this.updateBuilderComponentSubscription){
      this.updateBuilderComponentSubscription.unsubscribe();
    }
  }
}
