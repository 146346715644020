export const environment = {
  production: true,
  ws_url: 'wss://b1.nandbox.net:443/nandbox/api/',
  http_url: "https://b1.nandbox.net:443/nandbox/business/",
  http_url_index: "https://b1.nandbox.net:443/nandbox/index/",
  client_id: "446452655386-qbhsrii2dh23jcr54k5qoc36f588f6vj.apps.googleusercontent.com",
  paypal_client_id: "AUwDIEHgfhXe0P4YoaLkpvcBGHvUMhqdQpNSjrFmvFkjVFDIkE1cX_2dtkHfLWcuZgGhNQ3RrH4QfFTu",
  http_upload_url: "https://b1.nandbox.net:443/nandbox/upload/uploadedImage",
  http_upload_msg_url: "https://b1.nandbox.net:443/nandbox/upload/",
  http_download_url: "https://b1.nandbox.net:443/nandbox/download/",
  http_fetch_url: "https://b1.nandbox.net:443/nandbox/metadata/?url=",
  local_db: 'ndbx',
  local_db_version: 1,
  map_api_key: 'AIzaSyDYC0eyYdnbU9rgIKqT35Bb237WBhPkDZY',
  go_url: "https://go.ndbx.me",
  page_url: 'https://page.linkmedia.me',
  newpage_url: 'http://localhost:4200/hub',

  index_account_id: '4512624418834818552',
  index_key: 'LKD%sINDEXf%^9sd',
  index_msisdn: 'nandboxweb',
  smtp_api_link: 'https://go.ndbx.me/validate-smtp',
  stripeKey: 'pk_live_1EKVpRT39UhFfAreTVVm985Q00CuOhsZYr',

  dl: 'https://dl.ndbx.me/',
  share_link: 'https://s.linkmedia.me/',

  admin_page: false
};
