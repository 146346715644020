import {
  Chat,
  Coupon,
  MapMarker,
  Media,
  Menu,
  Page,
  Product,
  ProductImage,
  ProductOption,
  ProductStore,
  SignupPatternUI,
  Smtp,
  ThirdPartyInfoUI,
  UIAttribute,
} from "./interface";

export class GetUpgradedChatList {
  readonly method = "getUpgradedChatList";
  constructor(public ref: string) {}
}
export class SelectChat {
  readonly method = "selectChat";
  constructor(
    public chat_id: string,
    public isAuth: number,
    public ref: string
  ) {}
}

export class CreateChat {
  readonly method = "createChat";
  constructor(public chat: Chat, public ref: string) {}
}
export class SetChat {
  readonly method = "setChat";
  constructor(public chat: Chat, public reference?: string) {}
}
export class listChat {
  readonly method = "listChats";
  readonly page_size = 24;
  constructor(
    public type: string,
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public group_id?: string,
    public name?: string,
    public created_date?: any
  ) {}
}

export class getChat {
  readonly method = "getChat";
  readonly p = 1;
  constructor(public chat_id: string, public reference: string) {}
}
export class DeleteGroup {
  readonly method = "deleteGroup";
  constructor(public group_id: string, public ref: string) {}
}

export class GetBookingExceptions {
  readonly method = "getBookingExceptions";
  constructor(
    public chat_id: string,
    public date: string,
    public reference: string
  ) {}
}
export class GetBookingPeriods {
  readonly method = "getBookingPeriods";
  constructor(public chat_id: string, public reference: string) {}
}
export class SetBookingPeriods {
  readonly method = "setBookingPeriods";
  constructor(
    public chat_id: string,
    public data: any[],
    public reference: string
  ) {}
}
export class RemoveBookingPeriods {
  readonly method = "removeBookingPeriods";
  constructor(
    public chat_id: string,
    public data: any[],
    public reference: string
  ) {}
}
export class SetBookingExceptions {
  readonly method = "setBookingExceptions";
  readonly clear = 1;
  constructor(
    public chat_id: string,
    public data: string[],
    public reference: string
  ) {}
}
export class GetChatList {
  readonly method = "getChatList";
  constructor(public ref: string) {}
}

export class GetMessageList {
  readonly method = "getMsgList";
  readonly page_size = 50;
  constructor(
    public chat_id: string,
    public recent: number,
    public ref: any,
    public message_id?: string,
    public reply_to_message_id?: string,
    public date?: string,
    public admin?: any,
    public seen?: any,
    public from_user_id?: any,
    public to_user_id?: any,
    public scheduled?: number,
    public user_id?: string,
    public award?: number,
    public max_gmid?: number,
    public min_gmid?: number,
    public page_number?: number,
    public tags?: string
  ) {}
}
export class GetMsgListLevel1 {
  readonly method = "getMsgListLevel1";
  readonly page_size = 50;
  constructor(
    public chat_id: string,
    public pid: string,
    public all: number,
    public ref: any
  ) {}
}
export class GetMsgMax {
  readonly method = "getMsgMax";
  constructor(public chat_ids: string[], public ref: any) {}
}

export class SendMessage {
  readonly method = "sendMessage";
  readonly echo = 1;
  constructor(
    public chat_id: string,
    public text: string,
    public reference: number,
    public bg_color?: string,
    public schedule_date?: number,
    public tab?: string,
    public tags?: string[],
    public web_page_preview?: number,
    public reply_to_message_id?: string,
    public to_user_id?: string,
    public c_code?: string,
    public c_exp?: number,
    public inline_menu?: any[],
    public menu_ref?: string,
    public sticky_title?: string,
    public sticky_desc?: string,
    public chat_settings?: number,
  ) {}
}
export class LikeMessage {
  readonly method = "likeMessage";
  readonly echo = 1;
  constructor(
    public chat_id: string,
    public message_id: string,
    public myLike: number
  ) {}
}
export class getChatCounters {
  readonly method = "getChatCounters";
  constructor(public chat_id: string, public message_ids: string[]) {}
}
export class SendCalendar {
  readonly method = "sendCalendar";
  readonly echo = 1;
  constructor(
    public chat_id: string,
    public calendar_id: string,
    public auto_accept: number,
    public title: string,
    public description: string,
    public photo: string,
    public json: any,
    public reference: number,
    public tab?: string,
    public tags?: string[],
    public web_page_preview?: number,
    public reply_to_message_id?: string,
    public to_user_id?: string,
    public c_code?: string,
    public c_exp?: number,
    public schedule_date?: number,
    public inline_menu?: any[],
    public menu_ref?: string
  ) {}
}
export class SendPhoto {
  readonly method = "sendPhoto";
  readonly echo = 1;
  constructor(
    public chat_id: string,
    public photo: string,
    public height: number,
    public width: number,
    public size: number,
    public reference: number,
    public caption?: string,
    public style?: number,
    public tab?: string,
    public tags?: string[],
    public reply_to_message_id?: string,
    public to_user_id?: string,
    public c_code?: string,
    public c_exp?: number,
    public schedule_date?: number,
    public inline_menu?: any[],
    public menu_ref?: string,
    public sticky_title?: string,
    public sticky_desc?: string,
    public chat_settings?: number,
  ) {}
}
export class SendVideo {
  readonly method = "sendVideo";
  readonly echo = 1;
  constructor(
    public chat_id: string,
    public video: string,
    public duration: number,
    public height: number,
    public width: number,
    public size: number,
    public reference: number,
    public caption?: string,
    public tab?: string,
    public tags?: string[],
    public reply_to_message_id?: string,
    public to_user_id?: string,
    public c_code?: string,
    public c_exp?: number,
    public schedule_date?: number,
    public inline_menu?: any[],
    public menu_ref?: string,
    public sticky_title?: string,
    public sticky_desc?: string,
    public chat_settings?: number,
  ) {}
}
export class SendAudio {
  readonly method = "sendAudio";
  readonly echo = 1;
  constructor(
    public chat_id: string,
    public audio: string,
    public duration: number,
    public size: number,
    public title: string,
    public reference: number,
    public caption?: string,
    public tab?: string,
    public tags?: string[],
    public reply_to_message_id?: string,
    public to_user_id?: string,
    public c_code?: string,
    public c_exp?: number,
    public schedule_date?: number,
    public inline_menu?: any[],
    public menu_ref?: string,
    public chat_settings?: number,
  ) {}
}
export class SendDocument {
  readonly method = "sendDocument";
  readonly echo = 1;
  constructor(
    public chat_id: string,
    public document: string,
    public size: number,
    public reference: number,
    public tab?: string,
    public tags?: string[],
    public reply_to_message_id?: string,
    public to_user_id?: string,
    public c_code?: string,
    public c_exp?: number,
    public schedule_date?: number,
    public inline_menu?: any[],
    public menu_ref?: string,
    public chat_settings?: number,
  ) {}
}
export class RecallMessage {
  readonly method = "recallMessage";
  constructor(
    public chat_id: string,
    public message_id: string,
    public reference: number,
    public reply_to_user_id?: string,
    public to_admin?: number,
    public reply_to_message_id?: string
  ) {}
}
export class listChatTags {
  readonly method = "listChatTags";
  readonly page_size = 50;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string
  ) {}
}
export class SetTags {
  readonly method = "addChatTag";
  constructor(
    public name: string,
    public description: string,
    public isPrivate: number,
    public chat_id: string,
    public ref: string,
    public id?: string
  ) {}
}
export class ListStore {
  readonly method = "listStore";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public collection_id?: string,
    public name?: string,
    public created_date?: any,
    public category?: string[]
  ) {}
}
export class ListProductItem {
  readonly method = "listProductItem";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public product_id?: string,
    public name?: string,
    public created_date?: any,
    public status?: string,
    public category?: string[]
  ) {}
}
export class ListCollectionItem {
  readonly method = "listCollectionItem";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public collection_id?: string,
    public name?: string,
    public created_date?: any,
    public category?: string[]
  ) {}
}
export class ListPaymentOrders {
  readonly method = "listPaymentOrders";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public group_id: string,
    public ref: string,
    public created_date?: any
  ) {}
}
export class ListUserCoupons {
  readonly method = "listUserCoupons";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public coupon_id?: string,
    public name?: string,
    public created_date?: any,
    public status?: string,
    public expire?: any
  ) {}
}

export class GetCampaignList {
  readonly method = "getCampaignList";
  readonly page_size = 24;
  constructor(public page_number: number, public ref: string) {}
}

export class GetAppConfig {
  readonly method = "getAppConfig";
  constructor(public reference: string, public genAI?:boolean) {}
}

export class ListUsers {
  readonly method = "listUsers";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public member_id?: string,
    public member_list?: string[],
    public name?: string,
    public msisdn?: string,
    public status?: string,
    public role_id?: number,
    public created_date?: any
  ) {}
}

export class BanMemberFromChat {
  readonly method = "banChatMember";
  constructor(
    public chat_id: string,
    public user_id: string,
    public ref: string
  ) {}
}
export class UnBanMemberFromChat {
  readonly method = "unbanChatMember";
  constructor(
    public chat_id: string,
    public user_id: string,
    public ref: string
  ) {}
}
export class PromoteChatMember {
  readonly method = "promoteChatMember";
  constructor(
    public chat_id: string,
    public user_id: string,
    public ref: string
  ) {}
}

export class RemoveMemberFromChat {
  readonly method = "removeChatMember";
  constructor(
    public chat_id: string,
    public user_id: string,
    public ref: string
  ) {}
}

export class GetWhitelistUsers {
  readonly method = "getWhitelistUsers";
  readonly page_size = 25;
  constructor(
    public page_number: number,
    public chat_id: string,
    public reference: string,
    public msisdn?: string
  ) {}
}

export class GetBlacklistUsers {
  readonly method = "getBlacklistUsers";
  readonly page_size = 25;
  constructor(
    public page_number: number,
    public chat_id: string,
    public reference: string,
    public msisdn?: string
  ) {}
}

export class ListMenus {
  readonly method = "listMenus";
  readonly page_size = 25;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public menu_id?: string,
    public name?: string,
    public status?: string,
    public created_date?: any
  ) {}
}

export class GetMenuPage {
  readonly method = "getMenuPage";
  constructor(public ref: string) {}
}

export class SetMenuPage {
  readonly method = "setMenuPage";
  constructor(public page: any, public reference: string) {}
}

export class CreateMyPage {
  readonly method = "createMyPage";
  constructor(public page: Page) {}
}
export class SetMyPage {
  readonly method = "setMyPage";
  constructor(public page: Page) {}
}

export class GetMyGallery {
  readonly method = "getMyGallery";
  constructor(public mediaType: string) {}
}

export class DeleteMyGallery {
  readonly method = "deleteMyGallery";
  constructor(public filename: string) {}
}

export class PublishAppConfig {
  readonly method = "publishAppConfig";
  constructor(
    public version: number,
    public track: number,
    public version_name?: string,
    public version_desc?: string
  ) {}
}

export class SetAppConfig {
  readonly method = "setAppConfig";
  constructor(
    public reference: string,
    public version: number,
    public appConfig: any,
    public onlineAppConfig: any,
    public onlineChannelConfig: any
  ) {}
}

export class ListAppRelease {
  readonly method = "listAppRelease";
  constructor(public ref: string) {}
}
export class GetApps {
  readonly method = "getApps";
}
export class GetAppStore {
  readonly method = "getAppStore";
}
export class GetAppsSetup {
  readonly method = "getAppsSetup";
}
export class SetupApp {
  readonly method = "setupApp";
  constructor(public app: any, public os: number) {}
}

export class GenerateApp {
  readonly method = "generateApp";
  constructor(
    public reference: string,
    public app_id: string,
    public os: number,
    public version_name?: string,
    public version_desc?: string,
    public android_format?: string
  ) {}
}

export class ListAppReleaseId {
  readonly method = "getAppRelease";
  constructor(public id: number) {}
}

export class SetAppleStore {
  readonly method = "setAppStore";
  constructor(public os: number, public app_store: any) {}
}

export class SetAppleCode {
  readonly method = "setAppleCode";
  constructor(public code: string) {}
}

export class Revoke {
  readonly method = "revoke";
  constructor(public type: string) {}
}

export class GetMyMedia {
  readonly method = "getMyMedia";
  readonly page_size = 24;
  constructor(
    public ref: string,
    public page_number: number,
    public mediaType: string,
    public chat_id: string,
    public aspectRatio?: string,
    public keyword?: string
  ) {}
}

export class SetProviderConfig {
  readonly method = "setProviderConfig";
  constructor(
    public provider_id: string,
    public config: any,
    public ref: string
  ) {}
}
export class GetProviderConfig {
  readonly method = "getProviderConfig";
  constructor(public ref: string, public provider_id?: string) {}
}
export class CreateApiToken {
  readonly method = "createApiToken";
  constructor(
    public ref: string,
    public user_id: string,
    public chat_id: string
  ) {}
}
export class GetApiToken {
  readonly method = "getApiToken";
  constructor(
    public ref: string,
    public user_id: string,
    public chat_id: string
  ) {}
}

export class ListBots {
  readonly method = "listBots";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public group_id?: string,
    public name?: string,
    public created_date?: any
  ) {}
}

export class GetCalendars {
  readonly method = "listCalendars";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public ref: string,
    public id?: string,
    public title?: string,
    public created_date?: any
  ) {}
}

export class CreateCalendar {
  readonly method = "createCalendar";
  constructor(
    public title: string,
    public description: string,
    public start_date: string,
    public end_date: string,
    public ref: string,
    public photo_id: string
  ) {}
}
export class SetCalendar {
  readonly method = "setCalendar";
  constructor(
    public id: string,
    public title: string,
    public description: string,
    public start_date: string,
    public end_date: string,
    public photo_id: string,
    public exceptions: string[],
    public ref: string
  ) {}
}
export class GetCalendar {
  readonly method = "getCalendar";
  constructor(public id: string, public ref: string) {}
}
export class GetCalendarDetails {
  readonly method = "getCalendarDetails";
  constructor(public calendar_id: string, public ref: string) {}
}
export class SetCalendarDetail {
  readonly method = "setCalendarDetail";
  readonly clear = 1;
  constructor(
    public calendar_id: string,
    public ref: string,
    public data: any
  ) {}
}

export class SetProductStoreMethod {
  readonly method = "setProductItem";
  constructor(
    public id?: any,
    public name?: string,
    public service_profile_id?: number,
    public type?: number,
    public price?: number,
    public image?: ProductImage[],
    public sku?: string,
    public status?: string,
    public compare_at_price?: number,
    public option?: ProductOption[],
    public attribute?: UIAttribute,
    public tag?: any[],
    public ref?: string
  ) {}
}
export class SetProductsStoreMethod {
  readonly method = "setProductItem";
  constructor(productStore: ProductStore, public ref?: string) {
    for (const key in productStore) {
      if (productStore.hasOwnProperty(key)) {
        this[key] = productStore[key];
      }
    }
  }
}

export class SetProductItemArray {
  readonly method = "setProductItemArray";
  constructor(
    public products: ProductStore[],
    public parent_id: number,
    public type: number,
    public ref: string
  ) {}
}

export class GetProductItemMethod {
  readonly method = "getProductItem";
  constructor(public id: string, public ref: string) {}
}
export class GetStockMethod {
  readonly method = "getStock";
  constructor(public product_ids: number[], public ref: string) {}
}
export class SetStockMethod {
  readonly method = "setStock";
  constructor(public data: any[], public ref: string) {}
}
export class RemoveProductItemMethod {
  readonly method = "deleteProductElement";
  constructor(public product_id: any, public ref: string) {}
}

export class SetCollectionsToProductMethod {
  readonly method = "setProductToCollections";
  constructor(
    public product_id: number,
    public collection_id: any[],
    public ref: string
  ) {}
}

export class SetCollectionMethod {
  readonly method = "setCollectionItem";
  constructor(
    public id?: number,
    public name?: string,
    public status?: string,
    public description?: string,
    public image?: ProductImage[],
    public product_style?: string,
    public json?: any,
    public soft_id?: string,
    public product?: any[],
    public category?: string,
    public ref?: string
  ) {}
}

export class GetCollectionMethod {
  readonly method = "getCollectionItem";
  constructor(public id: number, public ref: string) {}
}

export class SetCollectionProductMethod {
  readonly method = "setCollectionProduct";
  constructor(
    public collection_id: number,
    public product_id: number[],
    public ref: string
  ) {}
}

export class GetCollectionProductsMethod {
  readonly method = "getCollectionProduct";
  constructor(public id: any, public ref: string) {}
}

export class DeleteCollectionElement {
  readonly method = "deleteCollectionElement";
  constructor(public collection_id: any, public ref: string) {}
}

export class SetUserCoupon {
  readonly method = "setUserCoupon";
  constructor(coupon: Coupon) {
    for (const key in coupon) {
      if (coupon.hasOwnProperty(key)) {
        this[key] = coupon[key];
      }
    }
  }
}

export class GetUser {
  readonly method = "getUser";
  readonly p = 1;
  constructor(public user_id: string, public ref: string) {}
}

export class GetMyMemberChats {
  readonly method = "getMyMemberChats";
  constructor(
    public user_id: string,
    public status: string,
    public ref: string
  ) {}
}

export class SetChatMemberTags {
  readonly method = "setChatMemberTags";
  constructor(
    public user_id: string,
    public tags: string[],
    public chat_id: string,
    public ref: string
  ) {}
}

export class AddChatMember {
  readonly method = "addChatMember";
  constructor(
    public user_id: string,
    public chat_id: string,
    public ref: string
  ) {}
}
export class AddChatAdmin {
  readonly method = "addChatAdmin";
  constructor(
    public user_id: string,
    public chat_id: string,
    public ref: string
  ) {}
}
export class DemoteAdmin {
  readonly method = "demoteAdmin";
  constructor(
    public user_id: string,
    public chat_id: string,
    public ref: string
  ) {}
}
export class GetListChatTags {
  readonly method = "getListChatTags";
  constructor(public chat_id: string[], public ref: string) {}
}

export class SetRole {
  readonly method = "setRole";
  constructor(
    public user_id: string,
    public chat_id: string,
    public role: any,
    public ref: string
  ) {}
}

export class GetVerificationTemplate {
  readonly method = "getVerificationTemplate";
  constructor(public ref: string) {}
}

export class SendVerificationTemplate {
  readonly method = "setVerificationTemplate";
  constructor(public email: string, public sms: string, public ref: string) {}
}
export class ClearVerificationTemplate {
  readonly method = "clearVerificationTemplate";
  constructor(public email: boolean, public sms: boolean, public ref: string) {}
}

export class GetSmtp {
  readonly method = "getSmtp";
  constructor(public ref: string) {}
}

export class SetSmtpMethod {
  readonly method = "setSmtp";
  constructor(public smtp: Smtp, public ref: string) {}
}
export class GetMessagingVendor {
  readonly method = "getMessagingVendor";
  constructor(public name: string, public ref: string) {}
}

export class SaveThirdPartyInfoMethod {
  readonly method = "setThirdPartyInfo";
  constructor(public vendors: ThirdPartyInfoUI[], public ref: string) {}
}

export class TestThirdPartyInfoMethod {
  readonly method = "testMessagingVendor";
  constructor(public vendor: any, public ref: string) {}
}

export class SignUpDemoAccount {
  readonly method = "signUpDemoAccount";
  constructor(
    public deviceId: string,
    public os: string,
    public osVersion: string,
    public msisdn: string,
    public email: string,
    public type: string,
    public countryCode: string,
    public countryIso: string,
    public ref: string
  ) {}
}
export class RegisterDemoAccount {
  readonly method = "registerDemoAccount";
  constructor(
    public deviceId: string,
    public os: string,
    public osVersion: string,
    public msisdn: string,
    public email: string,
    public type: string,
    public countryCode: string,
    public tac: string,
    public ref: string
  ) {}
}
export class SetPaymentProvider {
  readonly method = "setPaymentProvider";
  constructor(
    public active: number,
    public config: any,
    public provider_id: string,
    public ref: string
  ) {}
}
export class GetPaymentProviders {
  readonly method = "getPaymentProviders";
  constructor(public ref: string) {}
}
export class GetTacDemoAccount {
  readonly method = "getTacDemoAccount";
  constructor(
    public countryCode: string,
    public msisdn: string,
    public ref: string
  ) {}
}

export class GetAdsProvidersMethod {
  readonly method = "getAdsProviders";
  constructor(public provider_id: string, public ref: string) {}
}

export class SetAdsProviderMethod {
  readonly method = "setAdsProvider";
  constructor(
    public provider_id: string,
    public config: any,
    public ref: string
  ) {}
}
export class GetBillingInfoMethod {
  readonly method = "getPaymentInfo";
  constructor(public ref: string) {}
}
export class GetInvoicesMethod {
  readonly method = "getInvoices";
  constructor(public ref: string) {}
}

export class SendBillingSubscription {
  readonly method = "subscribeToPlan";
  constructor(
    public phone: string,
    public name: string,
    public email: string,
    public card_token: string,
    public plan_id: string,
    public coupon?: string,
    public sub_main?: boolean,
    public lm_data?: any
  ) {}
}

export class SendCouponToServer {
  readonly method = "retrieveCoupon";
  constructor(public coupon_id: string) {}
}

export class AddNewCreditCard {
  readonly method = "createCard";
  constructor(public card_token: string) {}
}

export class SetDefaultCreditCard {
  readonly method = "setDefaultCard";
  constructor(public card_id: string) {}
}

export class DeleteCreditCard {
  readonly method = "deleteCard";
  constructor(public card_id: string) {}
}

export class SubscribeToAddons {
  readonly method = "subscribeToAddons";
  constructor(
    public plan_id: string,
    public sub_main: boolean,
    public coupon?: string
  ) {}
}

export class ChangePlanMethod {
  readonly method = "changePlan";
  constructor(
    public new_plan_id: string,
    public coupon?: string,
    public sub_main?: boolean
  ) {}
}

export class CancelStripeSubscription {
  readonly method = "cancelStripeSubscription";
  constructor(
    public all: boolean,
    public plan_id?: string,
    public reason?: string,
    public sub_main?: boolean,
    public cancel_quantity?: number
  ) {}
}
export class RevokeSubscription {
  readonly method = "revokeSubscription";
  constructor() {}
}

export class SetSignupPattern {
  readonly method = "setSignupPattern";
  constructor(
    public data: SignupPatternUI[],
    public operator: string,
    public ref: string
  ) {}
}
export class GetSignupPattern {
  readonly method = "getSignupPattern";
  readonly page_size = 24;
  constructor(public page_number: number, public ref: string) {}
}

export class AddBlacklist {
  readonly method = "addBlacklist";
  constructor(
    public signups: string[],
    public chat_id: string,
    public ref: string
  ) {}
}
export class AddWhitelist {
  readonly method = "addToWhitelist";
  constructor(
    public signups: SignupPatternUI[],
    public chat_id: string,
    public ref: string
  ) {}
}
export class DeleteSignupPattern {
  readonly method = "deleteSignupPattern";
  constructor(
    public pattern: string[],
    public operator: string,
    public ref: string
  ) {}
}
export class DeleteWhitelist {
  readonly method = "removeFromWhitelist";
  constructor(
    public signups: string[],
    public chat_id: string,
    public ref: string
  ) {}
}
export class DeleteBlacklist {
  readonly method = "removeFromBlacklist";
  constructor(
    public signups: string[],
    public chat_id: string,
    public ref: string
  ) {}
}
export class GetAppChannelSettings {
  readonly method = "getAppChannelSettings";
  constructor(public ref: string) {}
}
export class SetAppChannelSettings {
  readonly method = "setAppChannelSettings";
  constructor(public whiteList_enabled: boolean, public ref: string) {}
}

export class UpdateCompanyDetails {
  readonly method = "updateAppInfo";
  constructor(
    public company_name: string,
    public company_official_name: string,
    public company_url: string,
    public deep_link_schema: string,
    public ref: string
  ) {}
}

export class GetMyBots {
  readonly method = "getMyBots";
  constructor(public owner: number, public ref: string) {}
}
export class RemoveBot {
  readonly method = "removeBot";
  constructor(public user_id: string, public ref: string) {}
}
export class GetBotToken {
  readonly method = "getBotToken";
  constructor(
    public chat_id: string,
    public user_id: string,
    public ref: string
  ) {}
}
export class ViewBotToken {
  readonly method = "viewBotToken";
  constructor(
    public chat_id: string,
    public user_id: string,
    public ref: string
  ) {}
}
export class CreateBot {
  readonly method = "createBot";
  constructor(
    public name: string,
    public short_name: string,
    public ref: string
  ) {}
}

export class GetUserList {
  readonly method = "getUserList";
  readonly p = 1;
  constructor(public user_id: string[], public ref: string) {}
}
export class chatWithAI {
  readonly method = "chatWithAI";
  constructor(public prompt: string, public generate: boolean, public ref: string) {}
}
export class SetBot {
  readonly method = "setBot";
  constructor(
    public id: string,
    public name: string,
    public short_name: string,
    public status: string,
    public about: string,
    public filter: number,
    public inline: number,
    public is_public: number,
    public photo: Media,
    public paid: boolean,
    public product: Product[],
    public owner: number,
    public ref: string
  ) {}
}
export class PublishBot {
  readonly method = "publishBot";
  constructor(
    public id: string,
    public shortName: string,
    public ref: string
  ) {}
}
export class GetChatMember {
  readonly method = "getChatMember";
  constructor(
    public chat_id: string,
    public user_id: string,
    public ref: string
  ) {}
}
export class SetPrivileges {
  readonly method = "setPrivileges";
  constructor(
    public chat_id: string,
    public user_id: string,
    public privileges: string[],
    public ref: string
  ) {}
}

export class CancelMessageSchedule {
  readonly method = "cancelMessageSchedule";
  constructor(public message_id: string, public ref: string) {}
}

export class SetTax {
  readonly method = "setTax";
  constructor(public tax: any, public ref: string) {}
}
export class GetTax {
  readonly method = "getTax";
  constructor(public ref: string) {}
}

export class SetAppInfo {
  readonly method = "setAppInfo";
  constructor(public app_info: any, public ref: string) {}
}
export class GetAppInfo {
  readonly method = "getAppInfo";
  constructor(public ref: string) {}
}
export class SetShipping {
  readonly method = "setShipping";
  constructor(public shipping: any, public ref: string) {}
}
export class GetShipping {
  readonly method = "getShipping";
  constructor(public ref: string) {}
}

export class GetBotTemplates {
  readonly method = "getBotTemplates";
  constructor(public ref: string) {}
}
export class AddBot {
  readonly method = "addBot";
  constructor(public user_id: string, public ref: string) {}
}

export class SetMStore {
  readonly method = "setStore";
  constructor(
    public menu_id?: string,
    public currency?: string,
    public shop?: any,
    public image?: any,
    public name?: string,
    public description?: string,
    public id?: number,
    public is_default?: number,
    public category?: string,
    public ref?: string
  ) {}
}
export class DeleteMStore {
  readonly method = "deleteStore";
  constructor(public store_id: string[], public ref: string) {}
}

export class GetStore {
  readonly method = "getStore";
  constructor(public id?: number, public category?: string) {}
}

export class GetChatAdministrators {
  readonly method = "getChatAdministrators";
  constructor(public chat_id: string, public ref: string) {}
}
export class GetAppConfigHistory {
  readonly method = "getAppConfigHistory";
  constructor(public id: string, public ref: string) {}
}
export class RemoveAppRelease {
  readonly method = "removeAppRelease";
  constructor(public id: string, public ref: string) {}
}

export class ListPoll {
  readonly method = "listPoll";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public chat_id: string,
    public ref: string,
    public id?: string,
    public status?: string,
    public name?: string,
    public created_date?: any
  ) {}
}

export class SetPoll {
  readonly method = "setPoll";
  constructor(
    public id?: number,
    public menu_id?: string,
    public name?: string,
    public menu?: any[],
    public type?: string,
    public result_type?: string,
    public expiry_date?: number,
  ) {}
}
export class GetPoll {
  readonly method = "getPoll";
  constructor(public id: number) {}
}
export class DeletePoll {
  readonly method = "deletePoll";
  constructor(public id: number, public ref: string) {}
}

export class GetMyCallBacksStats {
  readonly method = "getMyCallbackStats";
  constructor(public message_id: string) {}
}

export class SetTemplateFunctionMethod {
  readonly method = "setTemplateFunction";
  constructor(
    public function_id?: any,
    public template_id?: any,
    public function_name?: string,
    public param?: any
  ) {}
}

export class SetAppConfigTemplate {
  readonly method = "setAppConfigTemplate";
  // readonly reference = CREATE_APP_TEMPLATE_REF;
  constructor(
    public reference: string,
    public id?: string,
    public name?: string,
    public category?: any,
    public appConfig?: any,
    public onlineAppConfig?: any,
    public onlineChannelConfig?: any
  ) {}
}

export class SetTemplateMessageMethod {
  readonly method = "setTemplateMessage";
  constructor(
    public function_id: any,
    public message_type: string,
    public message_id: string,
    public message: any,
    public template_id?: any
  ) {}
}

export class GetTemplateMethod {
  readonly method = "getTemplate";
  constructor(public template_id: any, public reference: any) {}
}

export class ManageAppMember {
  readonly method = "manageAppMember";
  constructor(public user_id: any, public op: any, public ref: string) {}
}
export class DeleteAppMember {
  readonly method = "deleteAppMember";
  constructor(public user_id: any, public ref: string) {}
}

export class RunAppConfigTemplate {
  readonly method = "runAppConfigTemplate";
  constructor(public template_id: any) {}
}

export class DeleteCoupon {
  readonly method = "deleteCoupon";
  constructor(public coupon_id: string, public reference: string) {}
}
export class RemoveCalendar {
  readonly method = "removeCalendar";
  constructor(public calendar_id: string, public reference: string) {}
}
export class SharePost {
  readonly method = "shareMessage";
  constructor(
    public from_user_id: string,
    public from_user_name: string,
    public message_id: string,
    public chat_id: string,
    public chat_name: string,
    public date: number,
    public text?: string,
    public attachment?: string
  ) {}
}

export class GetChatWithAI {
  readonly method = "getChatWithAI";
  constructor(public ref: string) {}
}

export class ResetAIChat {
  readonly method = "resetAIChat";
  constructor(public ref: string) {}
}
export class SetPollResult {
  readonly method = "setPollResult";
  constructor(public groupId: string, public menu: any,public message_ids?:any) {}
}
export class PublishPollResult {
  readonly method = "publishPollResult";
  constructor(public groupId: string,public menu_group: string,public message?:any){}
}

export class MessageAI {
  readonly method = "messageAI";
  constructor(public prompt: string, public state: number, public remind: any, public ref: string) {}
}

export class GetAIHistory {
  readonly method = "getAIHistory";
  constructor(public ref: string) {}
}

export class ClearAIHistory {
  readonly method = "clearAIHistory";
  constructor(public ref: string) {}
}

export class GetAIDefinition {
  readonly method = "getAIDefinition";
  constructor(public state: number, public ref: string) {}
}

export class UpdateAIDefinition {
  readonly method = "updateAIDefinition";
  constructor(public state: number, public system_prompt: string, public model: string, public next_state_id: number, public json_key: string, public structure: string, public system_question: string, public logical_condition: string, public web: string, public parameters: string, public user_prompt: string, public output: string, public mode: string, public ref: string) {}
}

export class GenerateAIApp {
  readonly method = "generateAIApp";
  constructor(public current_version: number, public app_ref: string, public ref: string) {}
}

export class GetUsersPaymentOrders{
  readonly method= 'getUsersPaymentOrders';
  readonly pageSize = 24;
  constructor(public page: number,public reference: string, public orderDate?: any, public status?: any,public accountId?: any, public paymentMethod?: any, public oid?: string, public senderId?: string, public receiverId?: string){}
}

export class GetOrderDetails{
  readonly method = 'getOrderDetails';
  constructor(public oid: string, public reference: string, public dateMonth?: any){}
}

export class GetMapMarkers {
  readonly method = "getMapMarkers";
  readonly page_size = 24;
  constructor(
    public page_number: number,
    public reference: string
  ) {}
}

export class CreateMapMarker {
  readonly method = 'createMapMarker';
  constructor(mapMarker: MapMarker, public reference: string) {
    for (const key in mapMarker) {
      if (mapMarker.hasOwnProperty(key)) {
        this[key] = mapMarker[key];
      }
    }
  }
}
export class SetMapMarker {
  readonly method = 'setMapMarker';
  constructor(mapMarker: MapMarker, public reference: string) {
    for (const key in mapMarker) {
      if (mapMarker.hasOwnProperty(key)) {
        this[key] = mapMarker[key];
      }
    }
  }
}

export class GetMapMarker {
  readonly method = 'getMapMarker';
  constructor(public id: string, public reference: string) {}
}

export class RemoveMapMarker {
  readonly method = 'removeMapMarker';
  constructor(public id: string, public reference: string) {}
}

export class GetPollResult {
  readonly method = 'getPollResult';
  constructor(public groupId: string, public menu_group: string){}
}

export class GetList {
  readonly method = 'searchItems';
  constructor(public query: any, public values: any, public action: string, public ref: string, public page_number: number, public page_size: number, public span_size: number){}
}
